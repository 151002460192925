import React from "react";
import "./Results.css";
import Images from '../../Images'

const Results = () => {
  return (
    <div className="results">
      <div className="f-heading">
        <div className="line1"></div>
        <h1>Results</h1>
        <div className="line1"></div>
      </div>

      <div className="result">
          <div className="student">
            <div className="s-img">
              <img src={Images.P1} alt="Student" />
            </div>
            <span>Saurav Goswami</span>
            <p>96/100</p>
            <p>Biology</p>
          </div>
          <div className="student">
            <div className="s-img">
              <img src={Images.P2} alt="Student" />
            </div>
            <span>Jaskirat Singh</span>
            <p>95/100</p>
            <p>Biology</p>
          </div>
          <div className="student">
            <div className="s-img">
              <img src={Images.P3} alt="Student" />
            </div>
            <span>Veerinder Singh</span>
            <p>90/100</p>
            <p>Biology</p>
          </div>
          <div className="student">
            <div className="s-img">
              <img src={Images.P6} alt="Student" />
            </div>
            <span>Ekamjot Singh</span>
            <p>98/100</p>
            <p>Biology</p>
          </div>
          <div className="student">
            <div className="s-img">
              <img src={Images.P4} alt="Student" />
            </div>
            <span>Monu Signla</span>
            <p>96/100</p>
            <p>Biology</p>
          </div>
          <div className="student">
            <div className="s-img">
              <img src={Images.P5} alt="Student" />
            </div>
            <span>Ravnoor Kaur</span>
            <p>96/100</p>
            <p>Biology</p>
          </div>
          <div className="student">
            <div className="s-img">
              <img src={Images.P7} alt="Student" />
            </div>
            <span>Sapna</span>
            <p>77/100</p>
            <p>Biology</p>
          </div>
          <div className="student">
            <div className="s-img">
              <img src={Images.P8} alt="Student" />
            </div>
            <span>Jasnoor Kaur</span>
            <p>76/100</p>
            <p>Biology</p>
          </div>
      </div>
    </div>
  );
};

export default Results;
