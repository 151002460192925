import React, { useEffect, useState } from 'react';
import axios from 'axios';

const VideoList = ({ apiKey, channelId }) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            part: 'snippet',
            channelId: channelId,
            maxResults: 10,
            order: 'date',
            type: 'video',
            key: apiKey,
          },
        });
        setVideos(response.data.items);
      } catch (error) {
        console.error('Error fetching videos: ', error);
      }
    };

    fetchVideos();
  }, [apiKey, channelId]);

  return (
    <div>
      <div className="video-list">
        {videos.map((video) => (
          <div key={video.id.videoId} className="video-item">
            {/* <h3>{video.snippet.title}</h3> */}
            <iframe
              src={`https://www.youtube.com/embed/${video.id.videoId}`}
              frameBorder="0"
              allowFullScreen
              className='ivideo'
              title={video.snippet.title}
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoList;
