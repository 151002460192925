import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./SocialMedia.css";

const SocialMedia = () => {
  return (
    <div>
      <div className="socialmedia">
        <div className="s-content">
          <div className="social-content">
            <p>
              Don’t Miss To Follow Us On
            </p>
            <span>Social Media</span>
          </div>
        </div>

        <div className="s-icons">
          <a href="https://www.instagram.com/superscholarspatiala/">
            {" "}
            <FontAwesomeIcon icon={faInstagram} />{" "}
          </a>
          <a href="#">
            {" "}
            <FontAwesomeIcon icon={faFacebook} />{" "}
          </a>
          <a href="https://www.youtube.com/channel/UCNPCt1_WGHAi1rvMrN6gfaA">
            {" "}
            <FontAwesomeIcon icon={faYoutube} />{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
