import React from "react";
import "./Home.css";
import Banner from "../../data/Banner/Banner";
import { useSpring, animated } from "react-spring";
import Images from "../../Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faGoogleScholar } from "@fortawesome/free-brands-svg-icons";

function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 400,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const Home = () => {
  return (
    <div className="home">
      <div className="header">
        <Banner />
      </div>

      <div className="whyus">
        <div className="us">
          <div className="years">
            <FontAwesomeIcon
              icon={faStar}
              size="4x"
              style={{ color: "#fff" }}
            />
            <br />
            <h2>
              <Number n={15} />
            </h2>
            <span>Years of Excellence</span>
          </div>
          <div className="years">
            <FontAwesomeIcon
              icon={faGoogleScholar}
              size="4x"
              style={{ color: "#fff" }}
            />
            <br />
            <h2>
              <Number n={500} /> +
            </h2>
            <span>Students Satisfied</span>
          </div>
          <div className="years">
            <FontAwesomeIcon
              icon={faUser}
              size="4x"
              style={{ color: "#fff" }}
            />
            <br />
            <h2>
              <Number n={10} /> +
            </h2>
            <span>Experienced Faculty</span>
          </div>
          <div className="years">
            <FontAwesomeIcon
              icon={faUsers}
              size="4x"
              style={{ color: "#fff" }}
            />
            <br />
            <h2>
              <Number n={95} /> %
            </h2>
            <span>Satisfied Parents</span>
          </div>
        </div>
      </div>

      <div className="addmission">
        <h2>
          Admissions Open Now!
          <br />
          Free 4 DAYS DEMO CLASSES!!
          <br />
          Click on this button to Fill the Form!
        </h2>
        <span className="open">
        <a href="https://forms.gle/VFjws15spe9fki8n8">Admissions Open</a>
          </span>
      </div>

      <div className="founder">
        <div className="f-heading">
          <div className="line"></div>
          <h1>Our Founder</h1>
          <div className="line"></div>
        </div>
        <div className="founder-info">
          <div className="f-info">
            <h3>Mrs. VANDHANA BEDI</h3>
            <span>
              M.Sc (Mathematics), B.Ed. & MA Education.
            </span>
            <br />
            <p>
              Mrs. Vandhana Bedi is the Founder & Director of Perfect Coaching
              Classes which is a premier Institution for all NEET and boards
              preparation. She has been mentoring and guiding all the aspirants
              for more than 10 years. She has more than 10 years of experience
              in the field of Mathematics.
            </p>
            <br />
            <p>
              Over 500+ students have had their careers effectively influenced
              by her superb teaching and coaching over the past few years,
              enabling them to matriculate into various esteemed institutions.
              This journey continues as a crusade. To sate her enthusiasm for
              teaching, she spends as much time as possible in the classroom.
              She feels that becoming a teacher is a lifelong goal for her, not
              just a job. She thinks that everyone can succeed in life if they
              are given the proper direction and advice to go in order to
              succeed in the cutthroat world.
            </p>
          </div>
          <div className="founder-img">
            <img src={Images.founder} alt="Founder" />
          </div>
        </div>
      </div>

      <div className="courses">
        <div className="f-heading">
          <div className="line"></div>
          <h1>Courses</h1>
          <div className="line"></div>
        </div>
        <div className="all-courses">
          <div className="c-course">
            <div className="c-courses">
              <img src={Images.Course} alt="" />
              <span>XI & XII Standard</span>
              <p>
                Perfect Coaching offers and the best coaching for Physics,
                Chemistry, Maths and Biology exam preparation, making it an
                ideal choice for aspirants.
              </p>
              <a href="/Contact">Know More</a>
            </div>
            <div className="c-courses">
              <img src={Images.Course1} alt="" />
              <span>NEET</span>
              <p>
                Perfect Coaching has consistently been recognised for providing
                the best prepared courses and outcomes for medical entrance
                exams.
              </p>
              <a href="/Contact">Know More</a>
            </div>
            <div className="c-courses">
              <img src={Images.Course2} alt="" />
              <span>Pre-Nurture & Career Foundation (8th to 10th)</span>
              <p>
                We offer courses for various Competitive Exams and Olympiads for
                the students. We create an environment to Creativity, Scientific
                Thinking in the students.
              </p>
              <a href="/Contact">Know More</a>
            </div>
            <div className="c-courses">
              <img src={Images.Course3} alt="" />
              <span>Pharma GPAT Wing Coaching for Pharma Subjects </span>
              <p>
                Commerce courses designed by Perfect Coaching include coaching
                for Professional Courses like GPAT Foundation & Final.
              </p>
              <a href="/Contact">Know More</a>
            </div>
            <div className="c-courses">
              <img src={Images.Course4} alt="" />
              <span>Professional Development Services</span>
              <p>
                Services 1. Custom Thesis Writing Services 2. Custom CV Writing
                Services 3. Custom Assignment and Project Writing Services
              </p>
              <a href="/Contact">Know More</a>
            </div>
            <div className="c-courses">
              <img src={Images.Course5} alt="" />
              <span>Career Counseling</span>
              <p>
                Unlock your potential and navigate your career journey with our
                expert career counseling services. We offer comprehensive
                guidance to help you achieve success in your chosen path.
              </p>
              <a href="/Contact">Know More</a>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery">
        <div className="f-heading">
          <h1>Our Memorable Moments</h1>
        </div>

        <div className="photo-grid">
          <div data-aos="flip-right" className="photo">
            <img src={Images.G1} alt="Photo 1" />
          </div>
          <div data-aos="flip-left" className="photo">
            <img src={Images.G2} alt="Photo 2" />
          </div>
          <div data-aos="flip-left" className="photo">
            <img src={Images.G3} alt="Photo 3" />
          </div>
          <div data-aos="flip-up" className="photo">
            <img src={Images.G4} alt="Photo 3" />
          </div>
          <div data-aos="flip-up" className="photo">
            <img src={Images.G5} alt="Photo 3" />
          </div>
          <div data-aos="flip-up" className="photo">
            <img src={Images.G6} alt="Photo 3" />
          </div>
          <div data-aos="flip-right" className="photo">
            <img src={Images.G7} alt="Photo 3" />
          </div>
          <div data-aos="flip-right" className="photo">
            <img src={Images.G8} alt="Photo 3" />
          </div>
          <div data-aos="flip-left" className="photo">
            <img src={Images.G9} alt="Photo 3" />
          </div>
        </div>
      </div>

      <div className="numbers">
        <div className="f-heading">
          <h1>Our Achievements</h1>
        </div>
        <div className="achievements">
          <div className="a-main">
            <span>
              <Number n={20} />
              Students
            </span>
            <p>NEET Top 100 AIR</p>
          </div>
          <div className="a-main">
            <span>
              <Number n={50} />
              Students
            </span>
            <p>XI AND XII Excellent Biology Boards Results</p>
          </div>
          <div className="a-main">
            <span>
              <Number n={40} />
              Students
            </span>
            <p>Foundation Wing Top Ranks in the Boards </p>
          </div>
          <div className="a-main">
            <span>Consistent Results</span>
            <p>Maths & Biology</p>
          </div>
        </div>
      </div>

      <div class="contact">
        <div class="map-form">
          <div data-aos="fade-up" class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3442.618464380535!2d76.3850865!3d30.3617914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39102914c650f9e3%3A0x718811712e638bca!2sSuper%20Scholars!5e0!3m2!1sen!2sin!4v1709542250601!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 + "px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div data-aos="fade-up" class="form">
            <div class="form-heading">
              <p>Contact Us</p>
            </div>

            <form action="">
              <div class="form-sec">
                <input type="text" placeholder="Your Name" required />
                <input type="text" placeholder="Your Email" required />
              </div>
              <div class="form-sec">
                <input type="text" placeholder="Your Phone" required />
                <input type="text" placeholder="Your Subject" required />
              </div>
              <div class="form-sec">
                <input type="text" placeholder="Your Class" required />
                <input type="text" placeholder="Your Message" required />
              </div>
              <div class="form-button-btn">
                <button>Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
