import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div className="c-contact">
      <div className="heading">
        <h1>Contact Us</h1>
      </div>
      <div className="contact-info">
        <div className="c-name">
          <span>SUPER SCHOLARS</span>
        </div>
        <div className="c-address">
          <div className="add">
            <p>
              SCO-16, Near Guru kirpa sweets,
              <br />
              Anand Nagar A, Anand Nagar,
              <br />
              Patiala, Punjab 147001.
            </p>
          </div>

          <div className="add">
            <p>
              +91-7355280495 <br /> +91-1757965840
            </p>
          </div>

          <div className="add">
            <p>superscholarspatiala@gmail.com</p>
          </div>
        </div>
      </div>

      <div className="map-info">
        <div data-aos="fade-up" class="form">
          <form action="">
            <div class="form-sec">
              <input type="text" placeholder="Your Name" required />
              <input type="text" placeholder="Your Email" required />
            </div>
            <div class="form-sec">
              <input type="text" placeholder="Your Phone" required />
              <input type="text" placeholder="Your Subject" required />
            </div>
            <div class="form-sec">
              <input type="text" placeholder="Your Class" required />
              <input type="text" placeholder="Your Message" required />
            </div>
            <div class="form-button-btn">
              <button>Send Message</button>
            </div>
          </form>
        </div>
        <div data-aos="fade-up" class="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3442.618464380535!2d76.3850865!3d30.3617914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39102914c650f9e3%3A0x718811712e638bca!2sSuper%20Scholars!5e0!3m2!1sen!2sin!4v1709542250601!5m2!1sen!2sin"
            width="100%"
            id="map"
            title="g-map"
            height="550"
            style={{ border: 0 + "px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
