import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Footer from './Components/Footer/Footer';
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Testimonials from "./Pages/Testimonials/Testimonials";
import Results from "./Pages/Results/Results";
import BackToTop from "./data/Backtotop/BacktoTop";
import Neet from "./Pages/Neet/Neet";
import Lectures from "./Pages/Lectures/Lectures";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/Results" element={<Results />} />
        <Route path="/Neet" element={<Neet />} />
        <Route path="/EducationalTouchups" element={<Lectures />} />
      </Routes>
      <BackToTop/>
      <Footer />
    </>
  );
}

export default App;