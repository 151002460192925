import React from "react";
import "./Testimonials.css";
import Vid1 from "../../Assets/Videos/video.mp4";
import Vid2 from "../../Assets/Videos/video1.mp4";
import Vid3 from "../../Assets/Videos/video2.mp4";
import Vid4 from "../../Assets/Videos/video3.mp4";
import Vid5 from "../../Assets/Videos/video4.mp4";

const Testimonials = () => {
  return (
    <div className="testimonial">
      <div className="f-heading">
        <div className="line"></div>
        <h1>Testimonials</h1>
        <div className="line"></div>
      </div>
      <div className="testo">
        <div className="video-testimonial">
          <video src={Vid1} controls controlsList="nodownload" muted></video>
        </div>
        <div className="video-testimonial">
          <video src={Vid2} controls controlsList="nodownload" muted></video>
        </div>
        <div className="video-testimonial">
          <video src={Vid3} controls controlsList="nodownload" muted></video>
        </div>
        <div className="video-testimonial">
          <video src={Vid4} controls controlsList="nodownload" muted></video>
        </div>
        <div className="video-testimonial">
          <video src={Vid5} controls controlsList="nodownload" muted></video>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
