import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import Images from "../../Images";

function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <div className="n-logo">
        <img src={Images.logo} alt="Logo" />
      </div>
      <nav ref={navRef}>
        <a href="/">Home</a>
        <a href="/About">About</a>
        <a href="/Results">Results</a>
        <a href="/Testimonials">Testimonials</a>
        <a href="/Contact">Contact</a>
        <a href="/EducationalTouchups">Educational Touchups</a>
        <a className="blink" href="/Neet">Neet Preperation Module</a>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn1" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
