import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Banner.css';
import Images from '../../Images';

const Banner = () => {
  return (
    <Carousel
      autoPlay
      interval={2000} // Adjust the autoplay interval in milliseconds
      infiniteLoop
      showStatus={false}
      showThumbs={false}
      emulateTouch
    >
      <div>
        <img src={Images.Banner4} alt="Slide 1" />
      </div>
      <div>
        <img src={Images.banner2} alt="Slide 2" />
      </div>
      <div>
        <img src={Images.banner1} alt="Slide 3" />
      </div>
      <div>
        <img src={Images.Banner5} alt="Slide 3" />
      </div>
      <div>
        <img src={Images.banner3} alt="Slide 3" />
      </div>
      <div>
        <img src={Images.banner6} alt="Slide 3" />
      </div>
    </Carousel>
  );
};

export default Banner;
