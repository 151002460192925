import React from "react";
import './Lectures.css';
import VideoList from "../../Common/Videolist" 

const Lectures = () => {
  const apiKey = "AIzaSyDvqE-JdPTiyJYBQUnIkLgKQi2DmcLz6No";
  const channelId = "UCNPCt1_WGHAi1rvMrN6gfaA";

  return (
    <div className="lectures">
      <header className="App-header">
        <h1>Educational Touchups</h1>
      </header>
      <main>
        <VideoList apiKey={apiKey} channelId={channelId} />
      </main>
    </div>
  );
};

export default Lectures;
