import React from "react";
import "./Footer.css";
import Images from "../../Images";
import SocialMedia from "../../Common/SocialMedia/SocialMedia";

const Footer = () => {
  return (
    <>
      <div className="social">
        <SocialMedia/>
      </div>
      <div className="footer">
        <div data-aos="fade-up" className="f-line"></div>
        <div className="f-content">
          <div className="logo-social">
            <div className="ls-logo">
              <div className="f-logo">
              <img src={Images.logo1} alt="logo" />
                <span>Super Scholars</span>
              </div>
              <p data-aos="fade-up">
                The main motive of our institute is to guide the students and
                train them effectively to get the best results possible in exams.
              </p>
            </div>
            <div className="newsletter">
              <form className="newsletter-form">
                <input type="email" id="email" placeholder="Email" required />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>

          <div className="getintouch">
            <div className="a-box">
              <div className="address">
                <div className="address-heading">
                  <span>Address</span>
                </div>
                <div className="actual-address">
                  <a href="https://maps.app.goo.gl/tJ422f6gJRn3RBKW7">
                  SCO-16, Near Guru kirpa sweets,
                  <br />
                  Anand Nagar A, Anand Nagar,
                  <br />
                  Patiala, Punjab 147001
                  </a>
                </div>
              </div>
              <div className="address">
                <div className="address-heading">
                  <span>Phone</span>
                </div>
                <div className="actual-address">
                  <a href="tel:7355280495">+91-7355280495</a>
                  <a href="tel:1757965840">+91-1757965840</a>
                </div>
              </div>
            </div>
            <div className="a-box">
              <div className="address">
                <div className="address-heading">
                  <span>Email</span>
                </div>
                <div className="actual-address">
                  <a href="mailto: superscholarspatiala@gmail.com">
                    superscholarspatiala@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-line"></div>
        <div className="bottom">
          <div className="copyright">
            <p>Copyright © [Super Scholars] All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
