import React from "react";
import { useSpring, animated } from "react-spring";
import "./About.css";

const About = () => {

  function Number({ n }) {
    const { number } = useSpring({
      from: { number: 0 },
      number: n,
      delay: 400,
      config: { mass: 1, tension: 20, friction: 10 },
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
  }
  return (
    <div className="about">
      <div className="how">
        <div className="howit">
          <span>How it Started</span>
          <br />
          <h1>Our Dream is to See Everyone Succeed.</h1>
          <br />
          <p>
            Over 500+ students have had their careers effectively influenced by
            her superb teaching and coaching over the past few years, enabling
            them to matriculate into various esteemed institutions. This journey
            continues as a crusade. To sate her enthusiasm for teaching, she
            spends as much time as possible in the classroom. She feels that
            becoming a teacher is a lifelong goal for her, not just a job. She
            thinks that everyone can succeed in life if they are given the
            proper direction and advice to go in order to succeed in the
            cutthroat world.
            Over 500+ students have had their careers effectively influenced by
            her superb teaching and coaching over the past few years, enabling
            them to matriculate into various esteemed institutions. This journey
            continues as a crusade. To sate her enthusiasm for teaching, she
            spends as much time as possible in the classroom. She feels that
            becoming a teacher is a lifelong goal for her, not just a job. She
            thinks that everyone can succeed in life if they are given the
            proper direction and advice to go in order to succeed in the
            cutthroat world.
          </p>
        </div>
        <div className="howit-img">
          <div className="h-img">
            
          </div>
          <div className="results">
            <div className="stats">
            <h2>
              <Number n={15} />
            </h2>
            <span>Years of Excellence</span>
          </div>
          <div className="stats">
            <h2>
              <Number n={500} /> +
            </h2>
            <span>Students Satisfied</span>
          </div>
          <div className="stats">
            <h2>
              <Number n={10} /> +
            </h2>
            <span>Experienced Faculty</span>
          </div>
          <div className="stats">
            <h2>
              <Number n={95} /> %
            </h2>
            <span>Satisfied Parents</span>
          </div>
          </div>
        </div>
      </div>

      <div className="faculty">
        <div className="f-heading">
          <div className="line2"></div>
          <h1>Our Experienced Team</h1>
          <div className="line2"></div>
        </div>
        <div className="team">
          <div className="members">
            <h2>Mrs. Vandhana Bedi</h2>
            <p>MSc (Maths)</p>
            <p>Director (Maths Teacher)</p>
          </div>
          <div className="members">
            <h2>Dr. Jashandeep Kaur</h2>
            <p>Bachelor of Dental Surgery</p>
            <p>Pursuing Internship</p>
            <p>Biology Teacher</p>
          </div>
          <div className="members">
            <h2>Ms Smriti</h2>
            <p>MSc (Chemistry)</p>
            <p>Chemistry Teacher</p>
          </div>
          <div className="members">
            <h2>Ms Nikita Sharma</h2>
            <p>MSc (Physics)</p>
            <p>Physics Teacher</p>
          </div>
          <div className="members">
            <h2>Dr. Onkar Bedi</h2>
            <p>Ph.D. (Pharmacology)</p>
            <p>Expert Advisor</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
