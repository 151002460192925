import React from "react";
import "./Neet.css";
import Images from '../../Images';
import annouce from "../../Assets/Videos/an.mp4";

const Neet = () => {
  return (
    <div className="Neet">
      <div className="neetheading">
        <span>Neet Preparation Modules</span>
      </div>
      <div className="neet-modules">
        <div className="module">
          <img src={Images.module1} alt="" />
          <p>Module 1</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module2} alt="" />
          <p>Module 2</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module3} alt="" />
          <p>Module 3</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module4} alt="" />
          <p>Module 4</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module5} alt="" />
          <p>Module 5</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module6} alt="" />
          <p>Module 6</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module7} alt="" />
          <p>Module 7</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module8} alt="" />
          <p>Module 8</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module9} alt="" />
          <p>Module 9</p>
          <span>Every Module at just ₹200</span>
        </div>
        <div className="module">
          <img src={Images.module10} alt="" />
          <p>Module 10</p>
          <span>Every Module at just ₹200</span>
        </div>
      </div>
      <div className="neetheading">
        <span>Benefits of Our Modules</span>
      </div>
      <div className="neetlist">
        <ul>
          <li>
            Comprehensive Coverage: Our NEET module offers a thorough
            examination of all subjects with a focus on objective-based
            questions, ensuring students are well-prepared for the exam's
            format.
          </li>
          <li>
            Exam-Style Practice: The module includes a wide range of practice
            questions meticulously crafted to simulate the NEET exam, enabling
            students to familiarize themselves with the question types and
            optimize their performance.
          </li>
          <li>
            Performance Tracking: Students can track their progress through
            detailed analytics and performance metrics, allowing them to
            identify areas of strength and weakness and tailor their study
            approach accordingly.
          </li>
          <li>
            Concept Reinforcement: Each question is designed not only to assess
            knowledge but also to reinforce key concepts, ensuring a deeper
            understanding and retention of the material.
          </li>
          <li>
            Customizable Learning: Our module offers flexibility, allowing
            students to customize their study experience by selecting specific
            topics or difficulty levels, thereby optimizing their preparation
            strategy for NEET success.
          </li>
        </ul>
      </div>
      <div className="neetheading">
        <span>Benefits of Online Doubt Session</span>
      </div>
      <div className="neetlist">
        <ul>
          <li>
            Personalized Attention: Our online doubt sessions provide students
            with dedicated time to address their specific module-related
            queries, ensuring each doubt is thoroughly clarified within the
            session's duration.
          </li>
          <li>
            Expert Guidance: Students have access to experienced tutors who are
            well-versed in the NEET module, offering clear explanations and
            effective strategies to resolve doubts and enhance understanding.
          </li>
          <li>
            Time-Efficient: With a structured duration of 1.5 hours per session,
            students can efficiently resolve multiple doubts within a single
            session, maximizing their learning potential without consuming
            excessive time.
          </li>
          <li>
            Convenience: Our online mode eliminates the need for travel,
            allowing students to participate in doubt sessions from the comfort
            of their own homes, saving both time and effort.
          </li>
          <li>
            Affordable Pricing: Priced at Rs 500 per session, our doubt
            resolution service offers exceptional value, providing affordable
            access to expert guidance and support for students aiming to excel
            in their NEET preparation.
          </li>
        </ul>
      </div>
      <div className="contact-neet">
        <p>Contact Us On this Number to Purchase our Modules</p>
        <a href="tel: +91-73552-80495">+91 73552 80495</a>
      </div>
      <div className="neetvideo">
      <iframe
              src={annouce}
              frameBorder="0"
              allowFullScreen
              className='anvideo'
              title='sdf'
            ></iframe>
      </div>
    </div>
  );
};

export default Neet;
