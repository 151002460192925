import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import './BacktoTop.css'; // Import CSS file for styling

const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Function to handle scroll event
    const handleScroll = () => {
        if (window.pageYOffset > 300) { // Adjust this value to change when the button appears
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Effect to add scroll event listener when component mounts
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Clean up function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to scroll to top when button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    return (
        <div>
            {isVisible && (
                <button className="back-to-top-button" onClick={scrollToTop}>
                    <FontAwesomeIcon icon={faArrowUp} size="xl" style={{color: "#ffffff",}} />
                </button>
            )}
        </div>
    );
};

export default BackToTop;
